<template>
    <div class="backdrop" />
</template>

<script>
export default {
    name: "Backdrop"
}
</script>

<style lang="scss" scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
}
</style>
